import React from "react"
import Layout from "../components/layout"

import Sezione from "../components/sezione"
import SEO from "../components/seo"
import CardPagina from "../components/cardPagina"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import BottoneBianco from "../components/bottoneBianco"

import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Tab,
  ListGroup,
  Tabs,
} from "react-bootstrap"

const FotovoltaicoAziendale = () => (
  <Layout isPagina={true}>
    <SEO
      title="Impianti fotovoltaici aziendali e industriali Lazio, Roma, Aprilia, Pomezia, Latina, Anzio, Nettuno"
      description="Realizzazione impianti fotovoltaici aziendali e industriali in tutto il Lazio. Componenti Made in Germany"
    />
    <BanneronePage title="Fotovoltaico Aziendale" />
    <Container className="mt-4 mb-4">
      <TitoloPagina title="Impianti fotovoltaici aziendali e industriali Lazio: Roma, Pomezia, Aprilia, Latina, Anzio, Nettuno" />

      <Sezione title="Opportunità per le Aziende">
        <CardPagina variante="bordoLeft bordoRight">
          <span>
            L’investimento per l’autoproduzione di energia attraverso il
            fotovoltaico, per l'azienda genera un ricavo netto durante tutta la
            vita utile dell’impianto. <br />
            <br />
            Un’opportunità realmente vantaggiosa che consente di ridurre il
            tempo di ammortamento a 4/5 anni per poi avere solo utile per i successivi 25 anni di garanzia: <br />
            <b>
              <br />
            </b>
          </span>
          <p className="text-center">
            <b>
              AUTOCONSUMO
              <br /> (risparmio dei costi in fattura dell’energia elettrica fino
              all'80-90%) <br />+<br /> SCAMBIO SUL POSTO <br />
              (remunerazione dell'energia ceduta in rete)
              <br /> + <br />
              SUPER AMMORTAMENTO DEL 106% <br />
              (bene strumentale)
            </b>
          </p>
          <p>
            In particolare un impianto fotovoltaico installato su un capannone
            industriale ha i seguenti vantaggi:
          </p>
          <p>
            <ul>
              <li>
                <p>
                  <span>
                    Risparmio sui costi in fattura dell’energia elettrica fino
                    al 90%
                    <br />
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Rimborso dell'energia ceduta in rete con il meccanismo dello
                    Scambio sul Posto
                    <br />
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Possibilità di ammortizzare l’impianto come un qualsiasi
                    bene strumentale con deducibilità del 106%
                    <br />
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Possibilità di realizzare un impianto fotovoltaico di grandi
                    dimensioni, utilizzando lo spazio sul tetto del capannone
                    senza nessun problema di spazio e di autorizzazioni
                    <br />
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Diversificazione degli investimenti aziendali in attività
                    extra-caratteristiche
                    <br />
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    L’investimento ha un pay-back (ritorno) certificato e
                    definito
                    <br />
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <span>
                    Protezione del bilancio dagli incrementi tariffari annuali
                    dell’energia elettrica
                    <br />
                  </span>
                </p>
              </li>
            </ul>
          </p>
          <p className="text-justify">
            L’installazione di un impianto fotovoltaico porta con se delle
            componenti emozionali ed etiche, che per l’azienda si posso
            trasferire in un rientro tangibile di immagine. Basti vedere la
            forte sensibilità verso chi effettua una scelta “verde”, che spesso
            viene tradotta ed inserita nel bilancio sociale che concretizza il
            comportamento ecosostenibile dell’azienda stessa. Fotovoltaico,
            energie rinnovabili ed energia pulita sono indubbiamente messaggi
            qualificanti per l’azienda rispetto al proprio mercato. È una scelta
            “verde” con zero emissioni di CO2.
          </p>
        </CardPagina>
        <CardPagina variante="white">
          <Row>
            <Col sm={4}>
              <img
                className="rounded mt-1 img-fluid mx-auto"
                src="../fotovoltaico-aziendale-30kw.jpg"
                alt="impianto Fotovoltaico aziendale 30kw"
              />
            </Col>
            <Col sm={4}>
              <img
                className="rounded mt-1 img-fluid mx-auto"
                src="../fotovoltaico-aziendale-agrario.jpg"
                alt="impianto Fotovoltaico aziendale agrario"
              />
            </Col>
            <Col sm={4}>
              <img
                className="rounded mt-1 img-fluid mx-auto"
                src="../fotovoltaico-aziendale-bracciano.jpg"
                alt="impianto Fotovoltaico aziendale Bracciano"
              />
            </Col>
            <Col xs={12} className="mt-3">
              <p className="titoloGrande text-center gridParity">
                CONSULENZA, PROGETTAZIONE, REALIZZAZIONE E MANUTENZIONE:
                <br />
                Impianti fotovoltaici aziendali ed industriali su coperture e
                capannoni: <br />
                <strong>prezzo medio € 800 al kWp.</strong>
              </p>
            </Col>
            <Col xs={12}>
              <BottoneBianco
                className="mt-3 mx-auto text-center variantePage"
                title="Richiedi un preventivo"
                link="richiesta-preventivo"
              />
            </Col>
          </Row>
        </CardPagina>
        <CardPagina variante="bordoBottom gridParity" className="">
          Con NSE srl la GRID PARITY nel Lazio è stata raggiunta: un kWh da
          fotovoltaico (0,10-0,12 €) costa meno di un kWh da combustibili
          fossili (0,17-0,20 €)
        </CardPagina>
      </Sezione>
    </Container>
  </Layout>
)

export default FotovoltaicoAziendale
